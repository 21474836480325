.boatSelectLink{
    pointer-events: auto !important;
}

.boatSelectLink,
.boatSelectLink:hover {
    color: var(--blue-alt);
}

.boatSelectLink:active {
    color: white;
}

.boatSelectLabel:active {
    color: white;
}

