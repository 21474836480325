.dataTable {
    width: 100%;
    text-align: center;
}

.dataTable thead {
    font-family: var(--title-font-family);
}

.dataTable td,
.dataTable th {
    padding: 0.5rem;
}

.dataTable th {
    background-color: var(--blue);
    border-right: 1px solid var(--light-gray);
    color: var(--secondary-bg-color);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: center;
}

.dataTable tr {
    border-top: 1px solid var(--light-gray);
}

.dataTable thead tr {
    border-top: 0;
}

.dataTable tr:nth-child(even) {
    background-color: var(--secondary-bg-color);
}

.dataTable tr:hover {
    background-color: var(--blue-overlay);
}

.dataTable td {
    border-right: 1px solid var(--light-gray);
}

.dataTable td:last-child,
.dataTable td:nth-last-child(2) {
    width: 3rem;
}

.dataTable th:last-child,
.dataTable td:last-child {
    border-right: none;
}

.dataTableContainer div.card {
    border-radius: 1.5rem;
    border-color: var(--light-gray);
    overflow: hidden;
    overflow-x: auto;
}

.dataTableContainer div.bottomMenu {
    width: 80%;
    text-align: left;
}

.dataTableContainer button {
    color: var(--secondary-bg-color);
    background-color: var(--blue-alt);
    padding: 0.375rem;
    font-family: var(--title-font-family);
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid var(--blue-alt);
    border-radius: 0.25rem;
}

.dataTableContainer button:disabled {
    border: 1px solid var(--light-gray);
    background-color: var(--light-gray);
}

.dataTableContainer div.bottomMenu button {
    width: 5rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
}

.dataTableContainer div.bottomMenu button:nth-child(2) {
    width: 10rem;
}

.dataTableContainer input:first-child {
    border: 0;
    margin-bottom: 0.5rem;
}

.dataTableContainer span:first-child {
    font-weight: bold;
}
