.searchBox input {
    font-size: 1.375rem;
    font-style: italic;
    color: var(--light-gray);
}

.searchSelect {
    border-radius: 1.5rem !important;
    color: var(--gray);
}

.searchSelectTypeahead a:active {
    background-color: var(--blue);
}


.searchLink{
    pointer-events: auto;
    color: var(--blue-alt);
}

.searchLink:active,
.searchLabel:active {
    color: white;
}