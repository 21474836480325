.form {
    background-color: var(--secondary-bg-color);
    border: 1px solid var(--light-gray);
    border-radius: 1.5rem;
    padding: 1.5rem;
}

.form input,
.form select,
.form textarea {
    border: 1px solid var(--light-gray);
    border-radius: 0.5rem;
}

.form textarea {
    height: 10rem;
}

.warning {
    color: var(--danger);
    font-size: 0.75rem;
}
