.footer {
    background-color: var(--secondary-bg-color);
    border-top: 1px solid var(--light-gray);
    max-height: 5.0625rem;
    bottom: 0;
}

.copyright {
    font-family: var(--primary-font-family);
    font-size: 15px;
    color: var(--main-text);
}

.footerLink {
    color: var(--main-text);
}

.footerLink:hover {
    color: var(--blue);
    transition: all .15s ease-in-out;
}