:root {
    --main-bg-color: #fcfcfc;
    --secondary-bg-color: #fff;
    --light-gray: #999;
    --gray: #434547;
    --main-text: #394955;
    --blue: #2bb3f7;
    --blue-alt: #33b1ff;
    --blue-overlay: rgba(43, 179, 247, 0.6);
    --blue-col-1: #35a2d8;
    --blue-col-2: #60bde2;
    --underline: #007cd8;
    --danger: #bb2124;
    --primary-font-family: "Roboto", sans-serif;
    --title-font-family: "Mulish", sans-serif;
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-bg-color: #fcfcfc;
        --secondary-bg-color: #fff;
        --light-gray: #999;
        --gray: #434547;
        --main-text: #394955;
        --blue: #2bb3f7;
        --blue-alt: #33b1ff;
        --blue-overlay: rgba(43, 179, 247, 0.6);
        --blue-col-1: #35a2d8;
        --blue-col-2: #60bde2;
        --underline: #007cd8;
        --danger: #bb2124;
    }
}

body {
    min-width: 20rem;
    background-color: var(--main-bg-color);
    font-family: var(--primary-font-family);
    color: var(--main-text);
}

.max-container {
    max-width: 80rem;
}

.page-height {
    min-height: 100vh;
}

h1 {
    font-family: var(---title-font-family);
    font-size: 3.25rem;
    font-weight: bold;
    color: var(--blue-alt);
    text-align: center;
    text-transform: uppercase;
}

.active {
    color: #2bb3f7 !important;
}

.card-text:last-child {
    margin-bottom: auto;
}

.boatSelectTypeahead a.active{
    background-color: var(--blue) !important;
    color: white !important;
}
