.mtN1 {
    margin-top: -0.25rem;
}

.inputGroupText,
.textMain{
    color: var(--main-text) !important;
}

.boatComparisonTable th,
.boatDifferencePercentageTable th,
.boatComparisonTable td,
.boatDifferencePercentageTable td  {
    color: var(--main-text);
}

.inputGroupFocus {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.inputGroupFocus:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: #86b7fe;
    transition: all .15s ease-in-out;
}

.inputRemoveFocus:focus,
.removeFocus:focus {
    box-shadow: none !important;
    border: none !important;
}

.removeFocus{
    transition: none !important;
}

.pointer:hover {
    cursor: pointer;
}

.btnMainOutline {
    color: var(--main-text) !important;
    border-color: var(--main-text) !important;
}

.btnMainOutline:hover,
.btnMainOutline:active {
    color: white !important;
    background-color: var(--main-text) !important;
}

.btnMainOutline:hover svg,
.btnMainOutline:active svg {
    transition: all .15s ease-in-out;
    fill: white;
}

.btnSwap:hover svg,
.btnSwap:active svg {
    fill: var(--blue-overlay);
    transition: all .15s ease-in-out;
}

.boatSelect {
    border-radius: 1.5rem !important;
    color: var(--gray);
}

.boatSelectTypeahead a:active {
    background-color: var(--blue);
}

.boatComparisonTable,
.boatDifferencePercentageTable,
.modal {
    border-radius: 1.375rem !important;
}

.boatComparisonLink,
.boatComparisonLink:hover {
    color: var(--blue-alt);
}

.tooltip {
    font-family: var(--primary-font-family);
}

.tooltip div:first-child::before {
    border-top-color: var(--main-text);
}

.tooltip div:last-child {
    background-color: var(--secondary-bg-color);
    font-weight: bold;
    color: var(--main-text);
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.boatModel1 {
    background-color: var(--blue-col-1) !important;
}

.boatModel2 {
    background-color: var(--blue-col-2) !important;
}

.boatCol1 {
    color: var(--blue-col-1) !important;
}
.boatCol2 {
    color: var(--blue-col-2) !important;
}

a.boatCol1:hover,
a.boatCol2:hover {
    color: var(--main-text) !important;
}

.highlightCellCol1 {
    background-color: rgba(53, 162, 216, 0.25) !important;
}

.highlightCellCol2 {
    background-color: rgba(96, 189, 226, 0.25) !important;
}

/* Mobile view styles */
.btnModal:hover svg,
.btnModal:active svg {
    fill: var(--blue);
    transition: all .15s ease-in-out;
}

.btnModal:hover,
.btnModal:active {
    color: var(--blue) !important;
}

th.btnModal:hover,
th.btnModal:active{
    transition: all .15s ease-in-out;
}

.borderThickBottom {
    border-bottom: 4px solid #dfdfdf !important;
}

.borderThickTop,
.borderThickNoDiffBottom {
    border-top: 4px solid #dfdfdf !important;
}