.main-container {
    background: linear-gradient(
            rgba(43, 179, 247, 0.6),
            rgba(43, 179, 247, 0.6)
        ),
        url("../components/homepage/landing-bg.webp");
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.helpButton:hover {
    color: var(--gray);
    border-color: var(--gray);
    transition: all 0.15s ease-in-out;
}

.footerContainer {
    margin-top: 10rem;
}

.footer {
    display: flex;
}

.icons {
    margin: auto;
}

.instagramicon {
    padding-right: 2rem;
}

.copyright p {
    color: #fff;
    font-family: var(--primary-font-family);
}

.home-icons {
    height: 3.5rem;
    width: 3.5rem;
}
