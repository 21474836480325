.cardTitle {
    font-family: var(---title-font-family);
    font-size: 2rem !important;
    font-weight: bold !important;
    color: var(--blue-alt);
    padding-top: 1rem;
    padding-left: 1.5rem;
}
.cardBody {
    margin-left: 0.5rem;
    font-family: var(--primary-font-family);
    font-size: 1.1rem;
}
.footerText {
    background-color: rgba(0, 0, 0, 0.01);
    font-family: var(--primary-font-family);
    font-size: 0.85rem;
    margin-right: 0.5rem;
    border: 1px solid var(--light-gray);
    border-radius: 1rem;
    padding: 0.2rem;
    text-align: center;
    color: var(--light-gray);
}
.footerFlex {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.4rem;
    padding-bottom: 0.5rem;
}
.cardBorder {
    border-radius: 1.5rem !important;
    overflow: auto !important;
}

.blog-page .post a,
.blog-page .post a:hover {
    color: var(--blue-alt);
    text-decoration: none;
}

hr {
    width: 94%;
    align-self: center;
}

.post-body blockquote {
    position: relative;
    margin: 1rem 0;
    padding: 0.3rem 0.5rem 0.3rem 1.87rem;
    background: none repeat scroll 0 0 var(--secondary-bg-color);
    color: var(--light-gray);
    border: none;
    border-left: 0.5rem solid var(--blue-overlay);
}

.post-body blockquote p {
    margin-bottom: 0;
}

.post-body h2,
.post-body h3,
.post-body h4,
.post-body h5,
.post-body h6 {
    font-family: var(--primary-font-family);
    font-weight: bold;
    text-align: center;
}

.page-body hr {
    border-color: var(--main-text);
}

.post-body a:hover {
    text-decoration: underline !important;
}

.post-body table {
    text-align: center;
}

.post-body thead {
    font-family: var(--title-font-family);
}

.post-body td,
.post-body th {
    padding: 0.5rem;
    border: 1px solid var(--light-gray);
}

.post-body th {
    background-color: var(--blue);
    color: var(--secondary-bg-color);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.post-body tr:nth-child(even) {
    background-color: var(--secondary-bg-color);
}

.post-body tr:hover {
    background-color: var(--blue-overlay);
}