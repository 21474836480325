div.cardStyle {
    border-radius: 1.5rem;
    height: 15rem;
    text-align: center;
}

div.cardStyle,
.cardStyle div,
.cardStyle h2 {
    border-color: var(--light-gray);
}