.loginForm {
    background-color: var(--secondary-bg-color);
    border: 1px solid var(--light-gray);
    border-radius: 1.5rem;
    padding: 3rem;
    margin: auto;
}

.loginForm input {
    border-radius: 0.5rem;
    border-color: var(--light-gray);
}
