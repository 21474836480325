.editor {
    width: 100%;
    height: 31rem;
    border: 1px solid var(--light-gray);
    border-radius: 0.5rem;
    padding: 2px;
}

.editor div:first-child {
    border-color: var(--light-gray);
}

.editor hr {
    border-color: var(--main-text);
}