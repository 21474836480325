.deleteBtn,
.customBtn,
.clearBtn,
.contactBtn,
.loginBtn {
    color: var(--secondary-bg-color);
    padding: 0.375rem;
    font-family: var(--title-font-family);
    font-weight: bold;
    border-radius: 0.25rem;
}

.customBtn,
.contactBtn,
.loginBtn {
    background-color: var(--blue-alt);
    border: 1px solid var(--blue-alt);
}

.deleteBtn {
    background-color: var(--danger);
    border: 1px solid var(--danger);
}

.deleteBtn:hover,
.customBtn:hover,
.clearBtn:hover,
.contactBtn:hover,
.loginBtn:hover {
    text-decoration: underline;
}

.clearBtn {
    background-color: var(--bs-yellow);
    border: 1px solid var(--bs-yellow);
}

/* Disabled */
button:disabled {
    background-color: var(--light-gray);
}
.helpButton {
    background-color: transparent;
    border: 1px solid #fff;
    color: white;
    border-radius: 00.25rem;
    width: 7rem;
}
.contactBtn {
    width: 35%;
    text-transform: uppercase;
    border-radius: 0.75rem;
}
.loginBtn {
    width: 25%;
    text-transform: uppercase;
    border-radius: 0.75rem;
}
