.contactForm {
    background-color: var(--secondary-bg-color);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.5rem;
    padding: 1.5rem;
    margin: auto;
}

.contactForm input,
.contactForm textarea {
    border-radius: 0.5rem;
}

.sendbutton {
    text-align: center;
    padding-top: 0.5rem;
}

.form-label {
    text-transform: uppercase;
    font-family: var(--primary-font-family);
    font-weight: bold;
}
