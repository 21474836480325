.form blockquote {
    background: none repeat scroll 0 0 var(--secondary-bg-color);
    color: var(--light-gray);
    border-left: 0.5rem solid var(--blue-overlay);
}

.form h2,
.form h3,
.form h4,
.form h5,
.form h6 {
    font-family: var(--primary-font-family);
    font-weight: bold;
    text-align: center;
}

.form hr {
    border-color: var(--main-text);
}

.form a {
    font-style: italic;
    color: var(--blue-alt);
    text-decoration: none;
}

.form a:hover {
    text-decoration: underline;
}

.form thead {
    font-family: var(--title-font-family);
}

.form table thead tr th {
    background-color: var(--blue);
    border: 1px solid var(--light-gray);
    color: var(--secondary-bg-color);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: center;
}

.form table tr:nth-child(even) {
    background-color: var(--secondary-bg-color);
}

.form tr:hover {
    background-color: var(--blue-overlay);
}

.form table td {
    border: 1px solid var(--light-gray);
}
