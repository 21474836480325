img {
    width: 6rem;
}

.navText {
    font-weight: bold;
    font-size: 1.5rem;
    font-family: var(--title-font-family) !important;
}

.navText:hover {
    color: var(--blue) !important;
}

.navBar {
    height: 100%;
}

header {
    border-bottom: 1px solid var(--light-gray);
}
