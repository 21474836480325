.maincontainer {
    background-color: var(--secondary-bg-color);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.5rem;
    margin-bottom: 3rem;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
}

.linktag {
    padding-right: 2rem;
}

.linkp {
    font-family: var(--primary-font-family);
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 1.5rem;
}

.linkcontainer,
.linkcontainertwo {
    color: inherit;
    text-align: center;
}

.linkcontainer:link,
.linkcontainertwo:link {
    text-decoration: none;
}

.linkcontainer:hover,
.linkcontainertwo:hover {
    color: var(--blue-alt);
    transition: all 0.15s ease-in-out;
}

.refTitle {
    margin-bottom: 1.5rem;
    hyphens: auto;
}

.manTitle {
    margin-top: 2rem;
    margin-bottom: 2rem;
    hyphens: auto;
}

.refTitle,
.manTitle {
    font-family: var(---title-font-family);
    font-size: 3.25rem;
    font-weight: bold;
    color: var(--blue-alt);
    text-align: center;
    text-transform: uppercase;
}
