.listBlog td:nth-child(2) {
    min-width: 7rem;
}

.listBlog td:nth-child(3){
    text-align: start;
}

/* unset the above styles on any nested tables */
.listBlog td > div > table td:nth-child(2)  {
    min-width: unset;
}

.listBlog td > div > table,
.listBlog td > div > table td:nth-child(3) {
    text-align: center;
}

/* this selector does nothing as of april 12, 2022 but should give any div that has a table in it the correct border style when :has gets implemented*/
.listBlog td > div > table td > div:has(+ table) {
    border: 1px solid var(--light-gray);
    border-radius: 1.5rem;
    overflow: hidden;
}

.listBlog blockquote {
    position: relative;
    margin: 1rem 0;
    padding: 0.3rem 0.5rem 0.3rem 1.87rem;
    background: none repeat scroll 0 0 var(--secondary-bg-color);
    color: var(--light-gray);
    border: none;
    border-left: 0.5rem solid var(--blue-overlay);
}

.listBlog blockquote p {
    margin-bottom: 0;
}

.listBlog h2,
.listBlog h3,
.listBlog h4,
.listBlog h5,
.listBlog h6 {
    font-family: var(--primary-font-family);
    font-weight: bold;
    text-align: center;
}

.listBlog a {
    color: var(--blue-alt);
    text-decoration: none;
}

.listBlog a:hover {
    text-decoration: underline;
}